<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-input
					:clearable="true"
					@clear="SearchPageData"
					v-model="searchParams.carNumber"
					placeholder="请输入车辆编号"
					class="centerInput"
				></el-input>
			</el-form-item>
			
			<el-form-item>
				<el-input
					:clearable="true"
					@clear="SearchPageData"
					v-model="searchParams.carType"
					placeholder="请输入车辆类型"
					class="centerInput"
				></el-input>
			</el-form-item>
			
			<el-form-item>
				<el-select
					v-model="searchParams.parkId"
					:clearable="true"
					@change="SearchPageData"
					placeholder="请选择园区"
					class="centerSelInput"
				>
					<el-option v-for="item in ParkOption" :key="item.val" :label="item.text" :value="item.val">
					</el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item>
				<button class="centerCzbtn" @click="SearchPageData">搜索</button>
			</el-form-item>
		</el-form>
		
		<Table
			:data1="healthListConfig.list"
			:columns1="healthListConfig.columns"
			:total="healthListConfig.total"
			:loading="healthListConfig.loading"
			:destroy-on-close="true"
			@getAjax="LoadMoreHealthListData"
			style="width: 100%"
		/>
		
		<!--region 监测分析-->
		<el-drawer
			:title="healthAnalysisConfig.title"
			:visible.sync="healthAnalysisConfig.visible"
			:size="healthAnalysisConfig.size"
			:destroy-on-close="true"
		>
			<el-card class="car-info-container">
				<el-row>
					<el-col :span="6">
						<span class="title">车辆编号:</span>
						<span class="content">{{ healthAnalysisConfig.carNumber }}</span>
					</el-col>
					<el-col :span="6">
						<span class="title">车辆类型:</span>
						<span class="content">{{ healthAnalysisConfig.carType }}</span>
					</el-col>
					<el-col :span="6">
						<span class="title">车辆子类型:</span>
						<span class="content">{{ healthAnalysisConfig.carChildType }}</span>
					</el-col>
					<el-col :span="6">
						<span class="title">园区:</span>
						<span class="content">{{ healthAnalysisConfig.parkName }}</span>
					</el-col>
				</el-row>
			</el-card>
			
			<Table
				:data1="healthAnalysisConfig.list"
				:columns1="healthAnalysisConfig.columns"
				:total="healthAnalysisConfig.total"
				:loading="healthAnalysisConfig.loading"
				:destroy-on-close="true"
				@getAjax="LoadMoreHealthAnalysisListData"
				style="width: 100%"
			/>
		</el-drawer>
		<!--endregion 监测分析-->
		
		<!--region 监测分析详情-->
		<el-drawer
			:title="healthAnalysisDetailsConfig.title"
			:visible.sync="healthAnalysisDetailsConfig.visible"
			:size="healthAnalysisDetailsConfig.size"
			:destroy-on-close="true"
		>
			<el-card class="car-info-container">
				<el-row>
					<el-col :span="6">
						<span class="title">车辆编号:</span>
						<span class="content">{{ healthAnalysisDetailsConfig.carNumber }}</span>
					</el-col>
					<el-col :span="6">
						<span class="title">上传日期:</span>
						<span class="content">{{ healthAnalysisDetailsConfig.uploadDate }}</span>
					</el-col>
					<el-col :span="6">
						<span class="title">上电时间:</span>
						<span class="content">{{ healthAnalysisDetailsConfig.upTime }}</span>
					</el-col>
					<el-col :span="6">
						<span class="title">下电时间:</span>
						<span class="content">{{ healthAnalysisDetailsConfig.downTime }}</span>
					</el-col>
				</el-row>
			</el-card>
			
			<el-form :inline="true">
				<el-form-item>
					<el-time-picker
						is-range
						v-model="healthAnalysisDetailsSearchParams.timeRange"
						:clearable="true"
						:disabled="healthAnalysisDetailsConfig.realTimeMonitorStatus"
						class="centerDatepicker"
						range-separator="至"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						placeholder="选择时间范围"
						value-format="HH:mm:ss"
						@change="InitHealthAnalysisDetailsData"
					>
					</el-time-picker>
				</el-form-item>
				
				<el-form-item v-if="healthAnalysisDetailsConfig.realTime === 0">
					<el-button type="button" size="small" @click="SetRealtimeMonitor()" v-if="!healthAnalysisDetailsConfig.realTimeMonitorStatus">
						<i class="el-icon-video-play"></i> 实时监测
					</el-button>
					<el-button type="button" size="small" @click="SetRealtimeMonitor()" v-else>
						<i class="el-icon-video-pause"></i> 暂停监测
					</el-button>
				</el-form-item>
			</el-form>
			
			<el-row>
				<el-col :span="24">
					<div class="chart-list-container">
						<div class="chart-item">
							<div class="chart" id="status_signal_chart"></div>
						</div>
						
						<div class="chart-item">
							<div class="chart" id="value_signal_chart"></div>
						</div>
					</div>
				</el-col>
				<!--<el-col :span="6">
					<el-card class="car-info-container">
						<ul class="signal-type-list">
							<li>
								<span class="signal-color"></span>
								<span class="signal-name">低速报警</span>
							</li>
							<li>
								<span class="signal-color"></span>
								<span class="signal-name">驾驶模式</span>
							</li>
						</ul>
						
						<el-button type="text">
							<i class="el-icon-plus"></i>添加信号类
						</el-button>
					</el-card>
				</el-col>-->
			</el-row>
		
		</el-drawer>
		<!--endregion 监测分析详情-->
	</div>
</template>

<script>
	import Table from '@/components/Table.vue'
	import {_ParkOption} from "../../api/global";
	import {
		_FindHealthAnalysisDetails,
		_FindHealthAnalysisList,
		_FindHealthMonitoringList
	} from "../../api/dataAnalysis/health";
	import * as echarts from "echarts";
	
	export default {
		components: {
			Table
		},
		data() {
			return {
				ParkOption: [],
				
				searchParams: {
					parkId: "",
					carType: "",
					carNumber: "",
					beginDate: "",
					endDate: "",
					page: 1,
					pageSize: 20,
				},
				
				healthListConfig: {
					total: 0,
					loading: false,
					columns: [
						{
							label: "车辆编号",
							prop: "carNumber",
						},
						{
							label: "车辆类型",
							prop: "carChildType",
							width: 140,
						},
						{
							label: "所属园区",
							prop: "parkName",
						},
						{
							label: "最近一次上电",
							prop: "lastUpDate",
							width: 160,
						},
						{
							label: "最近一次下电",
							prop: "lastDownDate",
							width: 160,
						},
						{
							label: "最近一次CAN上传",
							prop: "lastUpCanDate",
							width: 160,
						},
						{
							label: "操作",
							width: 100,
							render: (h, params) => {
								return h("div", [
									h(
										"span", {
											class: "operationA",
											on: {
												click: () => {
													this.OpenHealthAnalysisDrawer(params.row);
												},
											},
										},
										"监测分析"
									),
								]);
							},
						}],
					list: [],
				},
				
				healthAnalysisConfig: {
					title: "车辆CAN数据分析",
					visible: false,
					size: "85%",
					total: 0,
					loading: false,
					carNumber: "",
					carType: "",
					carChildType: "",
					parkName: "",
					columns: [
						{
							label: "日期",
							prop: "date",
						},
						{
							label: "上电时间",
							prop: "beginTime",
							render: (h, params) => {
								if (params.row.beginTime === '' || params.row.beginTime === null || params.row.beginTime === undefined){
									return '-'
								}
								
								let arrData = params.row.beginTime.split(' ');
								
								return h('span', arrData[1]);
							}
						},
						{
							label: "下电时间",
							prop: "endTime",
							render: (h, params) => {
								if (params.row.endTime === '' || params.row.endTime === null || params.row.endTime === undefined){
									return '-'
								}
								
								let arrData = params.row.endTime.split(' ');
								
								return h('span', arrData[1]);
							}
						},
						{
							label: "操作",
							width: 100,
							render: (h, params) => {
								return h("div", [
									h(
										"span", {
											class: "operationA",
											on: {
												click: () => {
													this.OpenHealthAnalysisDetailsDrawer(params.row);
												},
											},
										},
										"分析详情"
									),
								]);
							},
						}],
					list: [],
				},
				
				healthAnalysisSearchParams: {
					carType: "",
					carNumber: "",
					dateRange: [],
					page: 1,
					pageSize: 20,
				},
				
				healthAnalysisDetailsConfig: {
					title: "车辆诊断分析",
					visible: false,
					size: "65%",
					carNumber: "",
					uploadDate: "",
					upTime: "",
					downTime: "",
					realTime: 0,	//是否实时状态。0：历史；1：实时
					realTimeMonitorStatus: false,	//是否实时监测状态。0：暂停；1：开启
					list: {
						statusType: [],
						valueType: [],
					},
				},
				
				healthAnalysisDetailsSearchParams: {
					carNumber: "",
					realTime: 0,
					signalType: 0,
					updateDate: "",
					beginDate: "",
					endDate: "",
					selectedSignal: [],
					timeRange: ['', ''],
					page: 1,
					pageSize: 20,
				},
				
				statusChartData: {
					legend: [],
					x: [],
					y: []
				},
				
				valueChartData: {
					legend: [],
					x: [],
					y: []
				},
			};
		},
		mounted() {
			this.InitPageData();
		},
		methods: {
			//获取园区选项
			async GetParkOption() {
				let res = await _ParkOption();
				
				if (res.success) {
					this.ParkOption = res.data;
				}
			},
			
			async InitPageData() {
				await this.GetParkOption();
				await this.GetHealthListData();
			},
			
			//region 监控监测列表
			async GetHealthListData() {
				this.healthListConfig.loading = true;
				
				let res = await _FindHealthMonitoringList(this.searchParams);
				
				if (res.success) {
					this.healthListConfig.list = res.data.list;
					this.healthListConfig.total = res.data.total;
				}
				
				this.healthListConfig.loading = false;
			},
			
			async LoadMoreHealthListData(pagination) {
				if (pagination) {
					if (pagination.page) {
						this.searchParams.page = pagination.page;
						this.searchParams.pageSize = pagination.limit;
					}
				}
				
				await this.GetHealthListData();
			},
			
			async SearchPageData() {
				this.searchParams.page = 1;
				
				await this.GetHealthListData();
			},
			//endregion 监控监测列表
			
			// region 监测分析
			async OpenHealthAnalysisDrawer(rowData) {
				this.healthAnalysisConfig.visible = true;
				
				this.healthAnalysisConfig.carNumber = rowData.carNumber;
				this.healthAnalysisConfig.carType = rowData.carTypeStr;
				this.healthAnalysisConfig.carChildType = rowData.carChildType;
				this.healthAnalysisConfig.parkName = rowData.parkName;
				
				this.healthAnalysisSearchParams.carNumber = rowData.carNumber;
				this.healthAnalysisSearchParams.carType = rowData.carChildType;
				
				await this.GetHealthAnalysisList();
			},
			
			async GetHealthAnalysisList(){
				let jsonParams = {
					carType: this.searchParams.carType,
					carNumber: this.searchParams.carNumber,
					beginDate: '',
					endDate: '',
					page: this.healthAnalysisSearchParams.page,
					pageSize: this.healthAnalysisSearchParams.pageSize,
				};
				
				if (this.healthAnalysisSearchParams.dateRange === null){
					this.healthAnalysisSearchParams.dateRange = [];
				}
				
				if (this.healthAnalysisSearchParams.dateRange.length === 2){
					jsonParams.beginDate = this.healthAnalysisSearchParams.dateRange[0];
					jsonParams.endDate = this.healthAnalysisSearchParams.dateRange[1];
				}
				
				let res = await _FindHealthAnalysisList(jsonParams);
				
				if (res.success){
					this.healthAnalysisConfig.list = res.data.list;
					this.healthAnalysisConfig.total = res.data.total;
				}
			},
			
			async LoadMoreHealthAnalysisListData(pagination){
				if (pagination) {
					if (pagination.page) {
						this.healthAnalysisSearchParams.page = pagination.page;
						this.healthAnalysisSearchParams.pageSize = pagination.limit;
					}
				}
				
				await this.GetHealthAnalysisList();
			},
			// endregion 监测分析
			
			// region 监测分析详情
			async OpenHealthAnalysisDetailsDrawer(rowData) {
				this.healthAnalysisDetailsConfig.visible = true;
				
				let sBeginTime = '';
				let sEndTime = '';
				
				if (rowData.beginTime !== '' && rowData.beginTime !== null && rowData.beginTime !== undefined){
					sBeginTime = rowData.beginTime.split(' ')[1];
				}
				
				if (rowData.endTime !== '' && rowData.endTime !== null && rowData.endTime !== undefined){
					sEndTime = rowData.endTime.split(' ')[1];
				}else{
					this.healthAnalysisDetailsConfig.realTime = 1;	//实时
				}
				
				this.healthAnalysisDetailsConfig.carNumber = rowData.carNumber;
				this.healthAnalysisDetailsConfig.uploadDate = rowData.date;
				this.healthAnalysisDetailsConfig.upTime = sBeginTime;
				this.healthAnalysisDetailsConfig.downTime = sEndTime;
				
				this.healthAnalysisDetailsSearchParams.carNumber = rowData.carNumber;
				this.healthAnalysisDetailsSearchParams.realTime = this.healthAnalysisDetailsConfig.realTime;
				this.healthAnalysisDetailsSearchParams.updateDate = rowData.date;
				this.healthAnalysisDetailsSearchParams.beginDate = rowData.beginTime;
				this.healthAnalysisDetailsSearchParams.endDate = rowData.endTime;
				
				this.InitHealthAnalysisDetailsData();
			},
			
			async InitHealthAnalysisDetailsData(){
				this.statusChartData.legend = [];
				this.statusChartData.x = [];
				this.statusChartData.y = [];
				
				this.valueChartData.legend = [];
				this.valueChartData.x = [];
				this.valueChartData.y = [];
				
				await this.GetStatusHealthAnalysisDetails();
				await this.GetValueHealthAnalysisDetails();
			},
			
			async GetStatusHealthAnalysisDetails(){
				this.healthAnalysisDetailsSearchParams.signalType = 1;
				
				let res = await _FindHealthAnalysisDetails(this.healthAnalysisDetailsSearchParams);
				
				if (res.success){
					this.healthAnalysisDetailsConfig.list.statusType = res.data;
					this.SetStatusChartData();
				}
			},
			
			async GetValueHealthAnalysisDetails(){
				this.healthAnalysisDetailsSearchParams.signalType = 2;
				
				let res = await _FindHealthAnalysisDetails(this.healthAnalysisDetailsSearchParams);
				
				if (res.success){
					this.healthAnalysisDetailsConfig.list.valueType = res.data;
					this.SetValueChartData();
				}
			},
			
			SetRealtimeMonitor(){
				this.healthAnalysisDetailsConfig.realTimeMonitorStatus = !this.healthAnalysisDetailsConfig.realTimeMonitorStatus;
			},
			
			//region 图表
			//设置状态类信号矩阵图表
			SetStatusChartData(){
				if (this.healthAnalysisDetailsConfig.list.statusType.length <= 0) return false;
				
				let yData = [];
				
				for (const item of this.healthAnalysisDetailsConfig.list.statusType) {
					this.statusChartData.legend.push(item.signalName);
					
					for (const chart of item.chart) {
						let arrTime = chart.time.split(' ');
						
						if (this.statusChartData.x.indexOf(arrTime[1]) === -1){
							this.statusChartData.x.push(arrTime[1]);
						}
						
						yData.push(chart.val);
					}
					
					this.statusChartData.y.push({
						name: item.signalName,
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: yData,
					})
				}
				
				let objChart = echarts.init(document.getElementById('status_signal_chart'));
				this.DrawChart(objChart, '状态类信号矩阵', this.statusChartData.legend, this.statusChartData.x, this.statusChartData.y);
			},
			
			//设置数值类信号矩阵图表
			SetValueChartData(){
				if (this.healthAnalysisDetailsConfig.list.valueType.length <= 0) return false;
				
				let yData = [];
				
				for (const item of this.healthAnalysisDetailsConfig.list.valueType) {
					this.valueChartData.legend.push(item.signalName);
					
					for (const chart of item.chart) {
						let arrTime = chart.time.split(' ');
						
						if (this.valueChartData.x.indexOf(arrTime[1]) === -1){
							this.valueChartData.x.push(arrTime[1]);
						}
						
						yData.push(chart.val);
					}
					
					this.valueChartData.y.push({
						name: item.signalName,
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: yData,
					})
				}
				
				let objChart = echarts.init(document.getElementById('value_signal_chart'));
				this.DrawChart(objChart, '数值类信号矩阵', this.valueChartData.legend, this.valueChartData.x, this.valueChartData.y);
			},
			
			//绘制图表
			DrawChart(chart, text, legendData, xData, yData) {
				chart.setOption({
					title: {
						text,
						itemGap: 20,
						left: 20,
						top: 20,
						textStyle: {
							color: '#666',
							fontSize: 16,
							fontFamily: "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
						},
						subtextStyle: {
							color: '#333',
							fontSize: 12,
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						top: '7%',
						data: legendData,
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: xData,	//x轴：日期
						axisLabel: {
							interval: 0, //X轴信息全部展示
							rotate: -90,
							margin: 30,
							textStyle: {
								align: 'center'
							}
						}
					},
					yAxis: {
						type: 'value',
					},
					grid: {
						top: '20%', // 等价于 y: '16%'
						left: '5%',
						right: '8%',
						bottom: '0%',
						containLabel: true
					},
					series: yData
				})
			}
			//endregion 图表
			// endregion 监测分析详情
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-card__body{padding: 15px 20px;}
	.car-info-container {
		width: 100%; overflow: hidden; text-align: center; margin-bottom: 20px;
		
		span{font-size: 14px; color: #333;}
		.title{padding-right: 5px;}
	}
	
	.signal-type-list{
		width: 100%; overflow: hidden; padding: 0;
		
		li{
			list-style: none; width: 100%; padding: 0; overflow: hidden; text-align: left; margin-bottom: 10px;
			
			span{float: left;}
			
			.signal-color{
				display: block; width: 10px; height: 10px; background-color: #63a35c;
				-webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
			}
			
			.signal-name{}
		}
	}
	
	.chart-list-container {
		width: 100%; overflow-x: auto;
		
		.chart-item{
			width: 100%; overflow: hidden; padding: 0; margin-bottom: 20px;
			
			p{padding: 0 20px; font-size: 16px; color: #333;}
			
			.chart{width: 100%; height: 300px;}
		}
	}
</style>
