import service from '@/until/api'

export function _FindHealthMonitoringList(data) {
    return service({
        url: '/report-data/healthMonitor/list',
        method: 'POST',
        data
    })
}

export function _FindHealthAnalysisList(data) {
    return service({
        url: '/report-data/healthMonitor/dataAnalysis',
        method: 'POST',
        data
    })
}

export function _FindHealthAnalysisDetails(data) {
    return service({
        url: '/report-data/healthMonitor/signalAnalysis',
        method: 'POST',
        data
    })
}
